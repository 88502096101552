import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'gstnStatus',
  validations: {
    selectedPeriodName: {
      value: {
        required
      }
    }
  },
  watch: {
    selectedLegalEntity: function() {
      this.parent_value_set_id = this.selectedLegalEntity.value;
    },
    currentPage: function() {
      this.getGstnStatusData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstnStatusData();
    }
  },
  data() {
    return {
      loading: false,
      showValueSetModal: false,
      setTimeVsetCode: null,
      vsetCode: null,
      parent_value_set_id: null,
      perPage: 10,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedFpgstn: {
        value: null,
        text: null
      },
      selectedPeriodName: {
        value: null,
        text: null
      },
      defaultValue: {
        value: null,
        text: null
      },
      gstnStatusData: [],
      gstnStatusFields: [
        {
          key: 'gst_Number',
          label: 'GST Number',
          class: 'text-center'
        },
        {
          key: 'gstr1_Download',
          label: 'GSTR1 Download',
          class: 'text-center'
        },
        {
          key: 'gstr3b_Download',
          label: 'GSTR3B Download',
          class: 'text-center'
        },
        {
          key: 'gstr2a_Download',
          label: 'GSTR2A Download',
          class: 'text-center'
        },
        {
          key: 'gstr2b_6a_Download',
          label: 'GSTR2B 6A Download',
          class: 'text-center'
        },
        {
          key: 'pr_Total_Tax',
          label: 'PR Total Tax',
          class: 'text-center'
        },
        {
          key: 'rec_Amt',
          label: 'Reco Amount',
          variant: 'success',
          class: 'text-center d-total-tax'
        },
        {
          key: 'unrec_Amt',
          label: 'Unreco Amount',
          variant: 'success',
          class: 'text-center d-total-tax'
        },
        {
          key: 'sales_Tax_Amt',
          label: 'Sales GST Amount',
          class: 'text-center'
        }
      ],
      rowDetails: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loading = true;
          const downloadpayload = {
            _page: this.currentPage - 1,
            _limit: this.perPage,
            le_id: this.selectedLegalEntity.value,
            gst_number: this.selectedFpgstn.value,
            period_name: this.selectedPeriodName.value
          };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/getGstnStatus',
            'gstn-status',
            () => (this.loading = false)
          );
        }
      }
    });
  },
  methods: {
    formattedNumber(num) {
      return commonHelper.formatCurrency(num);
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.selectedPeriodName = {
          value: null,
          text: null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN) {
        this.selectedFpgstn = {
          value: null,
          text: null
        };
      }
    },
    clear() {
      this.selectedLegalEntity = this.defaultValue;
      this.selectedFpgstn = this.defaultValue;
      this.selectedPeriodName = this.defaultValue;
      this.gstnStatusData = [];
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.selectedPeriodName = {
          value: item.value_code,
          text: item.period_name
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.selectedFpgstn = {
          value: item.value_code,
          text: item.gst_number
        };
      }
      this.showValueSetModal = false;
    },
    rowSelected(items) {
      this.rowDetails = items;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getGstnStatusData() {
      let payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectedLegalEntity.value,
        gst_Number: this.selectedFpgstn.value,
        period_name: this.selectedPeriodName.value
      };
      this.loading = true;
      this.$store
        .dispatch('complainceDashboard/getGstnStatus', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.gstnStatusData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'pr_Total_Tax') {
          elem.forEach(el => {
            el.classList.replace('d-total-tax', class2);
          });
        }
      } else {
        if (data === 'pr_Total_Tax') {
          let otherElem = document.querySelectorAll('.other-total-tax');
          otherElem.forEach(el => {
            el.classList.replace('other-total-tax', 'd-total-tax');
          });
        }
      }
    }
  }
};
